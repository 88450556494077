export default [
    {
        path: "/portfolio",
        text: "portfolio",
        scroll: false,
    },
    {
        path: "/blog",
        text: "blog",
        scroll: false,
    },
    {
        path: "latest",
        text: "latest",
        scroll: true,
    },
    {
        path: "about",
        text: "about",
        scroll: true,
    },
]