import React from 'react'
import { Link } from 'gatsby'
import Layout from 'src/components/layout'
import styles from 'src/styles/error.module.css'
import Banner from 'src/components/Banner'
import SEO from "src/components/seo"

const error = () => {
    return (
        <Layout>
            <SEO title="Error" />
            <header className={styles.error}>
                <Banner title="You have reached the end of the internet!">
                    <Link to="/" className="btn-white">Go Back</Link>
                </Banner>
            </header>
        </Layout>
    )
}

export default error