import React from 'react'
import Navbar from 'src/components/Navbar'
import Footer from 'src/components/Footer'
import 'src/styles/layout.css'

const Layout = ({children}) => {
    return (
        <main>
            <Navbar />
                {children}
            <Footer />
        </main>
    )
}

export default Layout