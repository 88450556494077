import React from 'react'
import { FaLinkedin, FaGithubSquare, FaEnvelopeSquare, FaYoutubeSquare } from 'react-icons/fa'

export default [
    {
        icon: <FaEnvelopeSquare />,
        url: "mailto:contact@sherifsabri.dev",
    },
    {
        icon: <FaLinkedin />,
        url: "https://www.linkedin.com/in/sherifsabri",
    },
    {
        icon: <FaGithubSquare />,
        url: "https://github.com/SherifSabri",
    },
    {
        icon: <FaYoutubeSquare />,
        url: "https://www.youtube.com/channel/UClxAlMiGSE50j_3-G1meJyA",
    },
]