import React from 'react'
import styles from 'src/styles/footer.module.css'
import socialIcons from 'src/constants/social-icons'
import { animateScroll as scroll } from 'react-scroll'
import { FaCaretSquareUp } from 'react-icons/fa'

const Footer = () => {
    const scrollToTop = () => { scroll.scrollToTop() }

    return (
        <footer className={styles.footer}>
                <div className={styles.icons}>
                    {socialIcons.map((item, index) => {
                        return (
                            <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                                {item.icon}
                            </a>
                        )
                    })}
                    <a href="#Top" aria-label="scroll to top" onClick={scrollToTop}><FaCaretSquareUp /></a>
                </div>
                <div className={styles.copyright}>
                    Copyright &copy; Sherif Sabri, {new Date().getFullYear()}
                </div>
        </footer>
    )
}

export default Footer